.Container {
  /* background-color: aqua; */
  position: relative;
  /* z-index: 50; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 5%;
}

.Logo img {
  width: 300px;
  height: 100px;
}

.Links a {
  font-family: "Open Sans", sans-serif;
  margin-right: 12px;
  font-weight: 500;
  text-decoration: none;
  color: black;
}

.Links a:hover {
  color: red;
}
