.AboutHeading {
  text-align: center;
  color: #222f3e;
  padding: 2% 0;
  font-size: 35px;
  letter-spacing: 2px;
}

.AboutText {
  /* background-color: coral; */
  text-align: center;
  padding: 0 30%;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

.Service {
  margin-top: 3%;
  background: url("../../Assets/Background/Service_Background.png");
  height: 90vh;
  background-position: center center;
  background-repeat: no-repeat;
  /* padding: 10% 0; */
}

.ServiceContainer {
  padding: 3% 0;
}

.ServiceContainer h2 {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
  letter-spacing: 2px;
}

.Services {
  display: flex;
  justify-content: space-around;
  margin: 0 20%;
  margin-top: 5%;
}

.TestimonialContainer {
  margin: 8% 0;
}

.TestimonialContainer h1 {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: 45px;
  letter-spacing: 2px;
  color: grey;
}

.Testimonials {
  display: flex;
  justify-content: space-between;
  margin: 3% 10%;
}

.ContactContainer {
  margin: 5% 0;
}

.Heading {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: grey;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 30px;
}

.ContactForm {
  padding: 3% 0%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.LeftContainer img {
  width: 75%;
  height: 250px;
}

.rightContainer {
  display: flex;
  margin-left: 10%;
  flex-direction: column;
  justify-content: center;
}

.input {
  width: 400px;
  margin-bottom: 4%;
  padding: 4%;
  border: 2px solid orange;
  outline-color: orange;
  border-radius: 10px;
}

.message {
  width: 400px;
  height: 200px;
  margin-bottom: 4%;
  padding: 4%;
  border: 2px solid orange;
  outline-color: orange;
  border-radius: 10px;
}

.submitBtn {
  background-color: #576574;
  padding: 3% 0;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 10px;
}

.maintainence {
  position: sticky;
  top: 0;
  height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #00b894;
  color: white;
}
