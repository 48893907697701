.Header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.Heading {
  position: relative;
  z-index: 30;
  text-transform: uppercase;
  margin-top: -20%;
  margin-left: 300px;
  font-size: 55px;
  color: #222f3e;
}

.textSection {
  margin-top: 50%;
}

.Header img {
  background-position: center center;
  background-repeat: no-repeat;
  height: 450px;
  width: 100%;
}

.Content {
  font-size: 18px;
  /* margin: 3% 25%; */
  line-height: 25px;
}

.points {
  margin: 4% 5%;
}

.points li {
  margin: 2% 0%;
}

.Boxes {
  /* background-color: aqua; */
  margin: 5% 20%;
  text-align: center;
  padding: 2% 0;
  font-size: 20px;
  font-weight: bold;
  border: 2px solid black;
}

.Boxes:hover {
  background-color: orange;
  color: white;
  cursor: pointer;
}
