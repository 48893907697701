.Container{
    bottom: 0;
    width: 100%;
    background-color: darkgray;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    padding:1% 10%;
    padding-top: 4%;
}

.icons{
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.text{
    font-family: "Open Sans", sans-serif;
    margin-top: 1%;
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: space-around;
    color: white;
}

.Copyright{
    margin: 0% 30%;
    color:white;
    text-align: center;
    font-weight: bold;
    margin-top: 10%;
}