.CardContainer {
  /* background-color: azure; */
  margin: 0% 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CardContainer img {
  width: 95%;
}

.Container {
  margin: 0%;
  padding: 10%;
  margin-top: -7%;
  background-color: white;
}

.Container h3 {
  color: orange;
  font-family: "Open Sans", sans-serif;
}

.Container ul {
  font-family: "Open Sans", sans-serif;
  margin-top: 10%;
}

.Container li {
  margin: 7% 0;
}

.Container li:hover {
  color: cadetblue;
  cursor: pointer;
}

.Container li a {
  color: grey;
  text-decoration: none;
}
