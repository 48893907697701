.Container{
    margin-left: 5%;
}

.topContainer{
    display: flex;
}

.text{
    margin-left: 3%;
    line-height: 30px;
}

.closingQuote{
    margin-top: 3%;
    display: flex;
    flex-direction: row-reverse;
}

.Writer{
    color:orange;
    margin-top: 3%;
    display: flex;
    justify-content: flex-end;
}

.Writer span{
    text-align: right;
    line-height: 25px;
    width: 60%;
}
