.Container {
  margin: 3% 25%;
}

.ServiceFooter {
  display: flex;
  align-items: flex-end;
  /* justify-content: flex-end; */
  flex-direction: column;
  margin-top: 10%;
}

.Points {
  display: flex;
}

.Points ul {
  margin-top: 3%;
  display: flex;
  list-style: none;
}

.Points li {
  margin-left: 20px;
  color: orange;
  font-weight: 600;
}

.Heading {
  font-size: 20px;
  font-weight: bold;
}
